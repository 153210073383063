body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




button[data-automationid="createWordCommand"] {
  visibility: "hidden";
  display: none;
}

button[data-automationid="createExcelCommand"] {
  visibility: "hidden";
  display: none;
}

button[data-automationid="createPowerPointCommand"] {
  visibility: "hidden";
  display: none;
}

button[data-automationid="createVisioCommand"] {
  visibility: "hidden";
  display: none;
}

button[data-automationid="malwareDownloadCommand"] {
  visibility: "hidden";
  display: none;
}
button[data-automationid="createOneNoteCommand"] {
  visibility: "hidden";
  display: none;
}

button[data-automationid="createExcelSurveyCommand"] {
  visibility: "hidden";
  display: none;
}

button[data-automationid="createFormForExcelCommand"] {
  visibility: "hidden";
  display: none;
}
li[class="ms-Breadcrumb-listItem listItem-49"]:first-child{
  display: none;
}

div [class="ms-LayoutGroup css-55"]{
  display: none;
}